import { Alert, Layouts, Loader, Modal, NoData, Table } from 'components'
import s from './styles.module.scss'
import { useParams } from 'react-router-dom'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Decrypt, downloadCsv, head } from 'helpers'
import * as api from 'api'

export default function Main() {
  const [leadsData, setLeadsData] = useState([])
  const [loading, setLoading] = useState([])
  const processing = useRef(false)

  let { businessId } = useParams()
  let businessName = ''
  if (businessId) {
    businessName = Decrypt(businessId).businessName
    businessId = Decrypt(businessId).businessId
  }

  useEffect(() => {
    head({ title: 'View Business Leads | BCN' })
  }, [])

  const DownloadLeadsHandler = () => {
    Alert.success('Downloading Leads...')
    downloadCsv(leadsData)
  }

  const getBusinessLeads = useCallback(async () => {
    if (processing.current) return
    processing.current = true
    setLoading(true)

    const fetchLeads = await api.userAdmin.businessLeads.fetchAll({ fkBusinessId: businessId })

    if (fetchLeads.code === 200) {
      setLeadsData(fetchLeads.payload.leads)
    } else {
      Alert.error('Leads not available')
    }

    setLoading(false)
    processing.current = false
  }, [businessId])

  useEffect(() => {
    if (businessId) getBusinessLeads()
  }, [businessId, getBusinessLeads])

  const deleteLeadHandler = async id => {
    if (processing.current) return
    processing.current = true

    const deleteLead = await api.userAdmin.businessLeads.delete({
      id
    })

    if (deleteLead.code === 201) {
      setLeadsData(leadsData.filter(x => x._id !== id))
      Alert.success(deleteLead.message)
    } else {
      Alert.error(deleteLead.error)
    }

    processing.current = false
  }

  const deleteAllHandler = async () => {
    if (processing.current) return
    processing.current = true

    const deleteAll = await api.userAdmin.businessLeads.deleteAll({ fkBusinessId: businessId })

    if (deleteAll.code === 201) {
      setLeadsData([])
      Alert.success(deleteAll.message)
    } else {
      Alert.error(deleteAll.error)
    }

    processing.current = false
  }

  return (
    <div className={s.main}>
      <div className={s.viewLeads}>
        {/* <div className={s.header}> */}
        <Layouts.Classic title={businessName ? 'Your leads for ' + businessName : 'Your Leads For “Business”'}>
          <div className={s.headerBottom}>
            <div
              className={s.deleteAll}
              onClick={
                !leadsData.length
                  ? () => {}
                  : () => Modal.Confirm('Are u sure u want to delete all your leads?', deleteAllHandler)
              }
            >
              <span className='material-icons-outlined'>delete</span>
              Close All
            </div>
            <div className={s.downloadLeads} onClick={leadsData.length ? DownloadLeadsHandler : () => {}}>
              Download Leads
              <span className='material-icons-outlined'>download</span>
            </div>
          </div>
        </Layouts.Classic>
        {/* </div> */}
        <div className={s.content + ' innerScrollX'} style={{ justifyContent: !leadsData.length && 'center' }}>
          {!loading ? (
            <div className={s.leadsData}>
              {!!leadsData.length ? (
                <Table.Leads LeadsData={leadsData} deleteLeadHandler={deleteLeadHandler} />
              ) : (
                <NoData />
              )}
            </div>
          ) : (
            <Loader color='var(--c-primary)' colorText='var(--c-primary)' />
          )}
        </div>
      </div>
    </div>
  )
}
