import handler from 'api/handler'

const prefix = '/userAdmin/leads'

const methods = {
  fetchAll: args => handler({ method: 'get', url: prefix + '/fetchAll', args }),
  delete: args => handler({ method: 'delete', url: prefix + '/delete', args }),
  deleteAll: args => handler({ method: 'delete', url: prefix + '/deleteAll', args })
}

export default methods
