import { timeFormat } from 'helpers'
import s from './styles.module.scss'
import images from 'images'
import { useState } from 'react'
import { Modal } from 'components'

export default function Main({ LeadsData, deleteLeadHandler }) {
  return (
    <div className={s.main}>
      <div className={s.table}>
        <div className={s.tableHeading}>
          <div className={s.srNo}>Id</div>
          <div className={s.contactPerson}>Contact Person</div>
          <div className={s.contact}>Contact</div>
          <div className={s.email}>E-mail</div>
          <div className={s.state}>State</div>
          <div className={s.city}>City</div>
          <div className={s.actions}>Actions</div>
        </div>
        <div className={s.tableBody}>
          {LeadsData.map(({ _id, contactPerson, phoneNo, email, createdAt, state, city, query }, i) => (
            <TableRow
              id={_id}
              contactPerson={contactPerson}
              phoneNo={phoneNo}
              email={email}
              createdAt={createdAt}
              state={state}
              city={city}
              query={query}
              deleteLeadHandler={deleteLeadHandler}
              index={i}
              key={i}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

const TableRow = ({ id, contactPerson, phoneNo, email, createdAt, state, city, query, index, deleteLeadHandler }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const isDiffQuery = query === 'Queried via call!!' || query === 'Queried via whatsapp!!'

  return (
    <>
      <div
        className={s.tableRow}
        style={{
          border: isDiffQuery && '0.1rem solid var(--c-primary)',
          marginTop: isDiffQuery && '1.5rem',
          borderTopLeftRadius: isDiffQuery && '0',
          borderBottomLeftRadius: isExpanded && '0',
          borderBottomRightRadius: isExpanded && '0',
          // borderBottom: isDiffQuery && isExpanded && '0px solid var(--c-primary)',
          boxShadow: isExpanded && '2px 4px 15px var(--c-primary-light)'
        }}
      >
        {!!isDiffQuery && <div className={s.pendingStatus}>{query}</div>}
        <div className={s.srNo}>{index + 1}</div>
        <div className={s.contactPerson}>{contactPerson}</div>
        <div className={s.contact}>{phoneNo}</div>
        <a className={s.email} href={email ? 'mailto:' + email : '/'}>
          {email || 'N/A'}
        </a>
        {!!query && (
          <span className={s.expand + ' material-icons-outlined'} onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? 'expand_less' : 'expand_more'}
          </span>
        )}
        <div className={s.state}>{state}</div>
        <div className={s.city}>{city}</div>
        <div className={s.actions}>
          <a href={'tel:+91' + phoneNo}>
            <span className='material-icons-outlined'>call</span>
          </a>
          <a href={'https://wa.me/91' + phoneNo} target='_blank' rel='noreferrer'>
            <img src={images.whatsapp} alt='' />
          </a>
          <div
            onClick={() =>
              Modal.Confirm('Are u sure u want to delete ' + contactPerson + "'s lead?", () => deleteLeadHandler(id))
            }
            title='Delete Business'
          >
            <span className='material-icons-outlined'>delete</span>
          </div>
        </div>
        <div className={s.createdAt}>{timeFormat(createdAt)}</div>
      </div>
      {!!isExpanded && (
        <div
          className={s.query}
          style={{
            border: isDiffQuery && '0.1rem solid var(--c-primary)',
            borderTop: isDiffQuery && 'none'
          }}
        >
          {query}
        </div>
      )}
    </>
  )
}
